import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import type { SyntheticEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutations } from '../../../../../../../api/hooks/useMutations';
import type { FileElaborato } from '../../../../../../../types/fascicolo-file';
import { Form } from '../../../../../form';

import { SoggettiTab } from './soggetti-tab';
import { TitoliTab } from './titoli-tab';
import { TitoliDTab } from './titolid-tab';

export function AttiIntervento(props: { data: FileElaborato, fileId: number; isSfavore: boolean}) {
  const [expanded, setExpanded] = useState<string | false>('panel1');

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { fascicolo } = useParams();

  const queryClient = useQueryClient();

  const handleSuccess = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [`fascicolo/${fascicolo}/file`, props.fileId],
      type: 'all',
      refetchType: 'active',
    });
  }, [fascicolo, props.fileId, queryClient]);

  const { create, isLoading } = useMutations({
    key: `fascicolo/${fascicolo}/attointervento`,
    onSuccess: handleSuccess,
  });

  const handleCreate = useCallback((values: any) => {
    const filteredNuoviSoggetti = values.data.soggetti_obj
      .filter((s: any) => s.crea && s.soggetto_esistente === 0);

    const filteredNuoviTitoli = values.data.titoli_obj
      .filter((s: any) => s.crea && s.titolo_esistente === 0);

    const filteredNuoviTitoliD = values.data.titolid_obj
      .filter((s: any) => s.crea && s.titolo_esistente === 0);

    create({
      data: {
        soggetti_obj: filteredNuoviSoggetti,
        titoli_obj: filteredNuoviTitoli,
        titolid_obj: filteredNuoviTitoliD,
        asfavore: props.isSfavore,
        smart: 1,
      },
    });
  },
  [create, props.isSfavore],
  );

  const renderButtonNew = useMemo(() => {
    const filteredNuoviSoggetti = props.data.soggetti_obj
      .filter((s: any) => s.soggetto_esistente === 0);

    const filteredNuoviTitoli = props.data.titoli_obj
      .filter((s: any) => s.crea && s.titolo_esistente === 0);

    const filteredNuoviTitoliD = props.data.titolid_obj
      .filter((s: any) => s.crea && s.titolo_esistente === 0);

    if (filteredNuoviSoggetti.length <= 0 && filteredNuoviTitoli.length <= 0 && filteredNuoviTitoliD.length <= 0) return null;

    return (
      <Box display="flex" justifyContent="end" sx={{ p: 1 }}>
        <Button disabled={isLoading} type="submit">
          Crea Entità
        </Button>
      </Box>
    );
  }, [isLoading, props.data.soggetti_obj, props.data.titoli_obj, props.data.titolid_obj]);

  return (
    <Form
      defaultValues={{ data: props.data }}
      onSubmit={handleCreate}
    >
      { props.data.soggetti_obj.length > 0 && (
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ height: '0px', minHeight: '46px!important', paddingLeft: 0 }}>
          <Typography sx={{ minHeight: '0px' }} variant="h5">
            Soggetti
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SoggettiTab soggetti={props.data.soggetti_obj} />
        </AccordionDetails>
      </Accordion>
      )}
      { props.data.titoli_obj.length > 0 && (
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ height: '0px', minHeight: '46px!important', paddingLeft: 0 }}>
          <Typography sx={{ minHeight: '0px' }} variant="h5">
            Mutui
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TitoliTab titoli={props.data.titoli_obj} />
        </AccordionDetails>
      </Accordion>
      )}
      { props.data.titolid_obj.length > 0 && (
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ height: '0px', minHeight: '46px!important', paddingLeft: 0 }}>
          <Typography sx={{ minHeight: '0px' }} variant="h5">
            Titoli
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TitoliDTab titoli={props.data.titolid_obj} />
        </AccordionDetails>
      </Accordion>
      )}
      {renderButtonNew}
    </Form>
  );
}
