import { CheckTwoTone, OpenInNewTwoTone } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { FileElaborato } from '../../../../../../../types/fascicolo-file';
import withConfirmationButton from '../../../../../with-confirmation';

const ButtonWithConfirmation = withConfirmationButton(Button);

export function ProgettoDistribuzioneProcess(props: { file: FileElaborato; onClose: () => void }) {
  const { fascicolo } = useParams();
  const navigate = useNavigate();

  const handleGoToCreateEsecuzione = useCallback(() => {
    const path = `/fascicolo/${fascicolo}/azioneesecutiva/add`;

    navigate(path, { state: props.file });
    props.onClose();
  }, [fascicolo, navigate, props]);

  const handleGoToCreateAltraProcedura = useCallback(() => {
    const path = `/fascicolo/${fascicolo}/altraprocedura/add`;

    navigate(path, { state: props.file });
    props.onClose();
  }, [fascicolo, navigate, props]);

  const handleGoToLinkedEsecuzione = useCallback(() => {
    const path = `/fascicolo/${fascicolo}/azioneesecutiva/${props.file?.link_azioneesecutiva}`;

    navigate(path);
    props.onClose();
  }, [fascicolo, props, navigate]);

  const handleGoToLinkedAltraProcedura = useCallback(() => {
    const path = `/fascicolo/${fascicolo}/altraprocedura/${props.file?.link_altraprocedura}`;

    navigate(path);
    props.onClose();
  }, [fascicolo, props, navigate]);

  // If the file has no title the file cannot be proccessed
  // if (!Object.values(props.file.link_tipologia ?? {}).length) {
  //   return null;
  // }

  if (props.file.link_tipologia === 1 && props.file.link_azioneesecutiva !== null) {
    return (
      <Button
        color="success"
        startIcon={<OpenInNewTwoTone />}
        variant="contained"
        onClick={handleGoToLinkedEsecuzione}
      >
        Vai all'esecuzione immobiliare
      </Button>
    );
  }

  if (props.file.link_tipologia === 2 && props.file.link_altraprocedura !== null) {
    return (
      <Button
        color="success"
        startIcon={<OpenInNewTwoTone />}
        variant="contained"
        onClick={handleGoToLinkedAltraProcedura}
      >
        Vai alla procedura
      </Button>
    );
  }

  if (props.file.link_tipologia === 1) {
    return (
      <ButtonWithConfirmation
        buttonProps={{
          color: 'success',
          variant: 'contained',
          startIcon: <CheckTwoTone />,
        }}
        message="Vuoi procedere alla creazione dell'esecuzione immobiliare?"
        onConfirm={handleGoToCreateEsecuzione}
      >
        Processa
      </ButtonWithConfirmation>
    );
  }

  return (
    <ButtonWithConfirmation
      buttonProps={{
        color: 'success',
        variant: 'contained',
        startIcon: <CheckTwoTone />,
      }}
      message="Vuoi procedere alla creazione della procedura?"
      onConfirm={handleGoToCreateAltraProcedura}
    >
      Processa
    </ButtonWithConfirmation>
  );
}
