import { Box, List } from '@mui/material';

import { MutuoFondiarioFormFields } from './mutuo-fondiario';

export function TitoliTab(props: { titoli: any}) {
  return (
    <Box sx={{ maxHeight: '300px' }}>
      <List sx={{ maxHeight: '300px', overflowY: 'auto' }} dense disablePadding>
        <Box pt={2} sx={{ minWidth: '1000px', overflowX: 'auto' }}>
          {props.titoli.map((_titolo: any, titoloIndex: number) => (
            <MutuoFondiarioFormFields titolo_index={titoloIndex} />
          ))}
        </Box>
      </List>
    </Box>
  );
}
