import { CloseTwoTone } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Divider,
  DialogActions,
  IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetOne } from '../../../../../../api/hooks/useGetOne';
import {
  Categoria,
  type FascicoloFile,
  type FileElaborato,
} from '../../../../../../types/fascicolo-file';

import { AdditionalRequest } from './additional-request';
import { AttiIntervento } from './atto-intervento-content';
import { AttoInterventoProcess } from './atto-intervento-content/process';
import { AvvisoCreditori } from './avviso-creditori-content';
import { AvvisoVenditaContent } from './avviso-vendita-content';
import { ProcessAvvisiVendita } from './avviso-vendita-content/process';
import { CertNotarileContent } from './cert-notarile-content';
import { ComunicazioneFallimento } from './comunicazione-fallimento-content';
import { ComunicazioneFallimentareProcess } from './comunicazione-fallimento-content/process';
import { CtuContent } from './ctu-content';
import { DecretoTrasferimentoContent } from './decreto-trasferimento-content';
import { ElaboratoContent } from './elaborato-content';
import { ElaboratoLoading } from './elaborato-loading';
import { FideiussioniContent } from './fideiussioni-content';
import { NuovoMutuoFondiario } from './mutuo-fondiario-content';
import { TitoliProcess } from './mutuo-fondiario-content/process';
import { NotaIscrizione } from './nota-iscrizione-content';
import { NotaTrascrizione } from './nota-trascrizione-content';
import { NuoveIstanzaVendita } from './nuova-istanza-vendita';
import { NuovaSentenzaFallimento } from './nuova-sentenza-fallimento';
import { ProcessSentenzaFallimento } from './nuova-sentenza-fallimento/process';
import { NuoviGaranti } from './nuovi-garanti';
import { ProcessGaranti } from './nuovi-garanti/process';
import { NuoviPrecetti } from './nuovi-precetti';
import { PrecettiProcess } from './nuovi-precetti/process';
import { NuoviSoggetti } from './nuovi-soggetti';
import { Process } from './nuovi-soggetti/process';
import { NuovoProgettoDistribuzione } from './nuovo-progetto-distribuzione-content';
import { ProgettoDistribuzioneProcess } from './nuovo-progetto-distribuzione-content/process';
import { OrdinanzaAssegnazioneContent } from './ordinanza-assegnazione-content';
import { ProcessOrdinanzaAssegnazione } from './ordinanza-assegnazione-content/process';
import { PignoramentoImmobiliare } from './pignoramento-immobiliare-content';
import { Rielabora } from './rielabora';
import { TitoloAFavore } from './titolo-a-favore';
import { TitoloAFavoreSave } from './titolo-a-favore-save';
import { VisuraCatastale } from './visura-catastale-content';

type Props = {
  file: FascicoloFile;
  onClose: () => void;
  open: boolean;
};
export function ElaborateDialog(props: Props) {
  const { fascicolo } = useParams();
  const [isSfavore, setIsSfavore] = useState(false);
  const { data, isLoading, isFetching } = useGetOne<FileElaborato>(
    `fascicolo/${fascicolo}/file`,
    props.file.id,
    { enabled: props.open },
  );

  const [asFavoreMemo, setIsAsFavoreMemo] = useState(null);

  const handleCheck = (value: string) => {
    data!.altraprocedura!.soggetto_creditore_terzo = value !== 'true';
  };

  const handleCheckFavore = (value: string) => {
    data!.titolo!.asfavore = value !== 'true';
    setIsSfavore(value !== 'true');
  };

  const handleCheckIstanzaFavore = (value: string) => {
    data!.asfavore = value !== 'true';
    setIsSfavore(value !== 'true');
  };

  useEffect(() => {
    setIsAsFavoreMemo(data?.asfavore);
  }, [data]);

  const loading = isLoading || isFetching;

  return (
    <Dialog maxWidth={false} open={props.open} onClose={props.onClose}>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <DialogTitle
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          variant="h4"
        >
          Risultato elaborazione
        </DialogTitle>
        <IconButton style={{ marginRight: '10px' }} onClick={props.onClose}>
          <CloseTwoTone />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        <Box maxWidth={1400} minWidth={800}>
          {loading && <ElaboratoLoading />}
          {!loading && data && <ElaboratoContent file={data} />}
          {!loading && data && data?.categoria === Categoria.precetti && <TitoloAFavore onCheckValue={handleCheckFavore} />}
          {!loading && data && data?.categoria === Categoria.istanza_vendita && <TitoloAFavore onCheckValue={handleCheckIstanzaFavore} />}
          {!loading && data && data?.categoria === Categoria.mutuo_fondiario && <TitoloAFavoreSave asdisablefavore={asFavoreMemo} asfavore={data!.asfavore} onCheckValue={handleCheckIstanzaFavore} />}
          {!loading && data && data?.categoria === Categoria.nota_iscrizione && <TitoloAFavoreSave asdisablefavore={asFavoreMemo} asfavore={data!.asfavore} onCheckValue={handleCheckIstanzaFavore} />}
          {!loading && data && data?.categoria === Categoria.nota_trascrizione && data?.descrizione_tipologia !== 2 && <TitoloAFavoreSave asdisablefavore={asFavoreMemo} asfavore={data!.asfavore} onCheckValue={handleCheckIstanzaFavore} />}
          {!loading && data && data?.categoria === Categoria.atto_intervento && <TitoloAFavoreSave asdisablefavore={asFavoreMemo} asfavore={data!.asfavore} onCheckValue={handleCheckIstanzaFavore} />}
          {!loading && data && data?.categoria === Categoria.pignoramento_immobiliare && <TitoloAFavoreSave asdisablefavore={asFavoreMemo} asfavore={data!.asfavore} onCheckValue={handleCheckIstanzaFavore} />}
          {!loading && data && data?.categoria === Categoria.progetto_distribuzione && <TitoloAFavoreSave asdisablefavore={asFavoreMemo} asfavore={data!.asfavore} onCheckValue={handleCheckIstanzaFavore} />}
          {!loading && data && data?.categoria === Categoria.istanza_ammissione_passivo && <AdditionalRequest file={data} onCheckValue={handleCheck} />}
          {!loading && data && data.fideiussioni && <FideiussioniContent fideiussioni={data.fideiussioni} />}
          {!loading && data && data?.categoria === Categoria.soggetti && (
            <NuoviSoggetti file={data} fileId={props.file.id} />
          )}
          {!loading && data && data?.categoria === Categoria.precetti && (
            <NuoviPrecetti file={data} fileId={props.file.id} isSfavore={isSfavore} />
          )}
          {!loading && data && data?.categoria === Categoria.istanza_vendita && (
            <NuoveIstanzaVendita data={data ?? {}} fileId={props.file.id} />
          )}
          {!loading && data && data?.categoria === Categoria.avviso_creditori && (
            <AvvisoCreditori data={data ?? {}} fileId={props.file.id} />
          )}
          {!loading && data && data?.categoria === Categoria.garanti && (
            <NuoviGaranti file={data} fileId={props.file.id} />
          )}
          {!loading && data && (data?.categoria === Categoria.sentenza_fallimento || data?.categoria === Categoria.istanza_ammissione_passivo) && (
            <NuovaSentenzaFallimento file={data} fileId={props.file.id} />
          )}
          {!loading && data && data.lotto_obj && data?.categoria === Categoria.avviso_vendita && (
            <AvvisoVenditaContent fileId={props.file.id} lotti={data.lotto_obj} />
          )}
          {!loading && data && data?.categoria === Categoria.ctu && (
            <CtuContent data={data ?? {}} fileId={props.file.id} />
          )}
          {!loading && data && data?.categoria === Categoria.certificazione_notarile && (
            <CertNotarileContent data={data ?? {}} fileId={props.file.id} />
          )}
          {!loading && data && data?.categoria === Categoria.decreto_trasferimento && (
            <DecretoTrasferimentoContent data={data ?? {}} fileId={props.file.id} />
          )}
          {!loading && data && data?.categoria === Categoria.ordinanza_assegnazione && (
            <OrdinanzaAssegnazioneContent fileId={props.file.id} titoli={data.titoli_obj} />
          )}
          {!loading && data && data?.categoria === Categoria.mutuo_fondiario && (
            <NuovoMutuoFondiario data={data ?? {}} fileId={props.file.id} isSfavore={isSfavore} />
          )}
          {!loading && data && data?.categoria === Categoria.nota_iscrizione && (
            <NotaIscrizione data={data ?? {}} fileId={props.file.id} isSfavore={isSfavore} />
          )}
          {!loading && data && data?.categoria === Categoria.nota_trascrizione && (
            <NotaTrascrizione data={data ?? {}} fileId={props.file.id} isSfavore={isSfavore} />
          )}
          {!loading && data && data?.categoria === Categoria.atto_intervento && (
            <AttiIntervento data={data ?? {}} fileId={props.file.id} isSfavore={isSfavore} />
          )}
          {!loading && data && data?.categoria === Categoria.pignoramento_immobiliare && (
            <PignoramentoImmobiliare data={data ?? {}} fileId={props.file.id} isSfavore={isSfavore} />
          )}
          {!loading && data && data?.categoria === Categoria.comunicazione_fallimento && (
            <ComunicazioneFallimento data={data ?? {}} fileId={props.file.id} isSfavore={isSfavore} />
          )}
          {!loading && data && data?.categoria === Categoria.progetto_distribuzione && (
            <NuovoProgettoDistribuzione data={data ?? {}} fileId={props.file.id} isSfavore={isSfavore} />
          )}
          {!loading && data && data?.categoria === Categoria.visura_catastale && (
            <VisuraCatastale data={data ?? {}} fileId={props.file.id} />
          )}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 3 }}>
        {!loading && data && data?.categoria === Categoria.precetti && <PrecettiProcess file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.ctu && <ProcessAvvisiVendita file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.ordinanza_assegnazione && <ProcessOrdinanzaAssegnazione file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.avviso_creditori && <ProcessAvvisiVendita file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.decreto_trasferimento && <ProcessAvvisiVendita file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.avviso_vendita && <ProcessAvvisiVendita file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.istanza_vendita && <ProcessAvvisiVendita file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.soggetti && <Process file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.garanti && <ProcessGaranti file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.mutuo_fondiario && <TitoliProcess file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.nota_iscrizione && <TitoliProcess file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.atto_intervento && <AttoInterventoProcess file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.pignoramento_immobiliare && <AttoInterventoProcess file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.comunicazione_fallimento && <ComunicazioneFallimentareProcess file={data} onClose={props.onClose} />}
        {!loading && data && data?.categoria === Categoria.progetto_distribuzione && <ProgettoDistribuzioneProcess file={data} onClose={props.onClose} />}
        {!loading && data && (data?.categoria === Categoria.sentenza_fallimento || data?.categoria === Categoria.istanza_ammissione_passivo) && <ProcessSentenzaFallimento file={data} onClose={props.onClose} />}
        {data?.rielabora === 0 && (<Rielabora fascicoloFileElaborazione_id={props.file?.fascicoloFileElaborazione_id || null} fileId={props.file.id} onClose={props.onClose} />)}
      </DialogActions>
    </Dialog>
  );
}
