import CheckIcon from '@mui/icons-material/Check';
import { Box, ListItem, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { CheckboxField } from '../../../../../../../form/fields/checkbox-field';
import { CurrencyField } from '../../../../../../../form/fields/currency-field';
import { DateField } from '../../../../../../../form/fields/date-field';
import { TextField } from '../../../../../../../form/fields/text-field';

export function DecretoIngiuntivoFormFields(props: { titolo_index: number }) {
  const { getValues } = useFormContext<any>();
  const titolo = getValues(`data.titolid_obj.${props.titolo_index}`);

  return (
    <ListItem key={`lotto_${props.titolo_index}`} sx={{ paddingLeft: 0, paddingRight: '10px' }}>
      <Box display="flex" gap={1} justifyContent="space-evenly" width="100%">
        {titolo.titolo_esistente === 0 && (
        <CheckboxField
          defaultValue="true"
          label=""
          name={`data.titolid_obj.${props.titolo_index}.crea`}
          sx={{ width: '100px' }}
        />
        )}
        {titolo.titolo_esistente === 1 && (
        <Tooltip
          title="Esistente"
          followCursor
        >
          <CheckIcon color="success" sx={{ marginTop: '10px' }} />
        </Tooltip>
        )}
        <TextField
          label="Tipologia"
          name={`data.titolid_obj.${props.titolo_index}.tipologia`}
          size="small"
          disabled
        />
        <TextField
          disabled={titolo.titolo_esistente === 1}
          label="Ufficio"
          name={`data.titolid_obj.${props.titolo_index}.ufficio`}
          size="small"
        />
        <TextField
          disabled={titolo.titolo_esistente === 1}
          label="Numero"
          name={`data.titolid_obj.${props.titolo_index}.numero_repertorio`}
          size="small"
        />
        <DateField
          DatePickerProps={{
            views: ['year'],
            label: 'Anno',
            inputFormat: 'YYYY',
            disabled: titolo.titolo_esistente === 1,
          }}
          name={`data.titolid_obj.${props.titolo_index}.anno`}
          size="small"
        />
        <CurrencyField
          disabled={titolo.titolo_esistente === 1}
          label="Importo"
          name={`data.titolid_obj.${props.titolo_index}.importo`}
          size="small"
        />
      </Box>
    </ListItem>
  );
}
